"use client";
import React from "react";
import cx from "classnames";
import Image from "next/image";
import { ButtonProps, ButtonType, ButtonShape } from "./types";
import "./Button.css";

const Button = (props: ButtonProps) => {
  const {
    type,
    children,
    id,
    className,
    onClick,
    shape,
    prefix,
    suffix,
    prefixIcon,
    suffixIcon,
    data,
    width,
    height,
    style,
    disabled,
  } = props;

  let curStyle = style;
  if (width) curStyle = { ...curStyle, width: `${width}px` };

  if (height) curStyle = { ...curStyle, height: `${height}px` };

  const handleClick = () => {
    if (!onClick) {
      return;
    }
    data ? onClick(data) : onClick();
  };

  return (
    <span
      id={id}
      className={cx(`button px-4 py-1.5 ${className}`, {
        "button-primary": type === ButtonType.Primary,
        "button-default": type === ButtonType.Default,
        "button-disabled": disabled === true,
        "button-circle": shape === ButtonShape.Circle,
        "button-quard": shape !== ButtonShape.Circle,
      })}
      style={{
        ...curStyle,
      }}
      onClick={handleClick}
    >
      {prefix ? prefix : null}
      {prefixIcon}
      {children}
      {suffix ? suffix : null}
      {suffixIcon}
    </span>
  );
};

export default Button;
