import { ReactNode } from "react";

export interface ButtonProps {
  type: ButtonType;
  children: React.ReactNode;
  id?: string;
  className?: string;
  disabled?: boolean;
  key?: string;
  shape?: ButtonShape;
  prefix?: ReactNode;
  suffix?: ReactNode;
  prefixIcon?: string;
  suffixIcon?: string;
  width?: number;
  height?: number;
  style?: Partial<React.CSSProperties> | undefined;
  data?: object;
  onClick?: (data?: any) => void;
  onMouseOver?: (data?: any) => void;
  onMouseOut?: (data?: any) => void;
}

export enum ButtonShape {
  Quard = "quard",
  Circle = "circle",
}

export const enum ButtonType {
  Default = "default",
  Primary = "primary",
}
